import React, { useState, useEffect, useCallback } from 'react';
import { Route, Navigate, Routes, useNavigate } from 'react-router-dom';
import Chat from './components/Chat';
import LoginForm from './components/LoginForm';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const navigate = useNavigate();

  /**
   * Check if the user is authenticated by calling the backend.
   * The backend uses HTTP-only cookies for authentication.
   */
  const checkAuth = useCallback(async () => {
    try {
      const response = await fetch('https://henosis.us/api/check-auth', {
        method: 'GET',
        credentials: 'include', // Ensure cookies are sent with the request
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Auth check error:', error);
      setIsLoggedIn(false);
    } finally {
      setIsAuthChecked(true); // Authentication check is complete
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  /**
   * Handle user login.
   * Sends login credentials to the backend.
   */
  const handleLogin = useCallback(async (loginData) => {
    try {
      const response = await fetch('https://henosis.us/api/login', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        console.log('Debug login info:', responseData); // Log debug information
        setIsLoggedIn(true);
        navigate('/chat');
      } else {
        alert(responseData.error || 'Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred during login. Please try again.');
    }
  }, [navigate]);

  /**
   * Handle user logout.
   * Sends a logout request to the backend to clear the authentication cookie.
   */
  const handleLogout = useCallback(async () => {
    try {
      const response = await fetch('https://henosis.us/api/logout', {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setIsLoggedIn(false);
        navigate('/login');
      } else {
        console.error('Failed to logout.');
        alert('Failed to logout. Please try again.');
      }
    } catch (error) {
      console.error('Logout error:', error);
      alert('An error occurred during logout. Please try again.');
    }
  }, [navigate]);

  /**
   * While the authentication status is being checked,
   * display a loading indicator to prevent flickering.
   */
  if (!isAuthChecked) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900 text-white">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <Routes>
      {/* Public Route: Login */}
      <Route
        path="/login"
        element={
          isLoggedIn ? <Navigate to="/chat" replace /> : <LoginForm onLogin={handleLogin} />
        }
      />

      {/* Protected Route: Chat */}
      <Route
        path="/chat"
        element={
          isLoggedIn ? <Chat onLogout={handleLogout} /> : <Navigate to="/login" replace />
        }
      />

      {/* Redirect Root to Appropriate Route */}
      <Route
        path="/"
        element={<Navigate to={isLoggedIn ? "/chat" : "/login"} replace />}
      />

      {/* Catch-All Route: Redirect to Root */}
      <Route
        path="*"
        element={<Navigate to={isLoggedIn ? "/chat" : "/login"} replace />}
      />
    </Routes>
  );
};

export default App;
