import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Plus, Paperclip, Send, ChevronDown, Settings, X } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import debounce from 'lodash/debounce';
import { VariableSizeList as List } from 'react-window'; // Use VariableSizeList

const UserMessage = React.memo(({ content }) => (
  <div className="bg-blue-500 p-2 rounded-lg whitespace-pre-wrap text-left max-w-[90%] overflow-x-auto">
    {content}
  </div>
));

const AssistantMessage = React.memo(({ content }) => (
  <ReactMarkdown
    className="bg-gray-700 p-2 rounded-lg max-w-[90%] overflow-x-auto"
    components={{
      code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return !inline && match ? (
          <SyntaxHighlighter
            style={vscDarkPlus}
            language={match[1]}
            PreTag="div"
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        );
      },
      p({ children }) {
        return <p className="whitespace-pre-wrap">{children}</p>;
      },
      h1({ children }) {
        return <h1 className="text-2xl font-bold mt-4 mb-2">{children}</h1>;
      },
      h2({ children }) {
        return <h2 className="text-xl font-bold mt-3 mb-2">{children}</h2>;
      },
      h3({ children }) {
        return <h3 className="text-lg font-bold mt-2 mb-1">{children}</h3>;
      },
      ul({ children }) {
        return <ul className="list-disc pl-6 space-y-1">{children}</ul>;
      },
      ol({ children }) {
        return <ol className="list-decimal pl-6 space-y-1">{children}</ol>;
      },
      li({ children }) {
        return (
          <li className="pl-1">
            <span className="inline">{children}</span>
          </li>
        );
      },
    }}
  >
    {content}
  </ReactMarkdown>
));

// Message component to measure its height
const Message = React.memo(({ index, style, data }) => {
  const { messages, setMessageHeight } = data;
  const message = messages[index];
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const height = ref.current.getBoundingClientRect().height;
      setMessageHeight(index, height);
    }
  }, [index, setMessageHeight]);

  return (
    <div
      style={style}
      className={`mb-4 ${message.role === 'user' ? 'text-right px-4' : 'text-left px-4'}`}
    >
      <div ref={ref}>
        {message.role === 'user' ? (
          <UserMessage content={message.content} />
        ) : (
          <AssistantMessage content={message.content} />
        )}
      </div>
    </div>
  );
});

const Chat = ({ onLogout }) => {
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const listRef = useRef(null);
  const containerRef = useRef(null);
  const sidebarCloseTimeoutRef = useRef(null);
  const messagesRef = useRef(null);
  const messageHeightsRef = useRef({});

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMouseOverSidebar, setIsMouseOverSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [threadsLoaded, setThreadsLoaded] = useState(false);
  const [pastes, setPastes] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isModelSelectorOpen, setIsModelSelectorOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [customInstructions, setCustomInstructions] = useState('');
  const [threads, setThreads] = useState([
    { name: 'General', messages: [], selectedModel: 'deepseek-chat' },
    { name: 'Random', messages: [], selectedModel: 'deepseek-chat' },
  ]);
  const [currentThreadIndex, setCurrentThreadIndex] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [listHeight, setListHeight] = useState(0);

  // Models list
  const models = [
    { value: 'claude-3-5-sonnet-20240620', label: 'Claude 3-5 Sonnet' },
    { value: 'gemini-1.5-flash-latest', label: 'Gemini 1.5 Flash Latest' },
    { value: 'gemini-1.5-pro-latest', label: 'Gemini 1.5 Pro Latest' },
    { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
    { value: 'gpt-4-turbo', label: 'GPT-4 Turbo' },
    { value: 'gpt-4o', label: 'GPT-4o' },
    { value: 'llama-3-sonar-large-32k-online', label: 'Llama 3 Sonar Large 32k Online' },
    { value: 'llama-3-sonar-large-32k-chat', label: 'Llama 3 Sonar Large 32k Chat' },
    { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus' },
    { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku' },
    { value: 'reka-core-20240501', label: 'Reka Core' },
    { value: 'reka-flash-20240226', label: 'Reka Flash' },
    { value: 'reka-edge-20240208', label: 'Reka Edge' },
    { value: 'deepseek-chat', label: 'DeepSeek Chat' },
    { value: 'o1-mini', label: 'o1 Mini' },
    { value: 'o1-preview', label: 'o1 Preview' },
  ];

  // Function to update list height
  const updateListHeight = useCallback(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.getBoundingClientRect().height;
      setListHeight(containerHeight);
    }
  }, []);

  // Initialize ResizeObserver to watch container size
  useEffect(() => {
    updateListHeight();

    const resizeObserver = new ResizeObserver(() => {
      updateListHeight();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    window.addEventListener('resize', updateListHeight);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      window.removeEventListener('resize', updateListHeight);
    };
  }, [updateListHeight]);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(messages.length - 1, 'end');
    }
  }, [messages]);

  // Update messagesRef.current whenever messages change
  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  // Function to save conversation
  const saveConversation = useCallback(
    async (threadName, messagesToSave, pasteCounter = 0) => {
      try {
        console.log('Saving conversation with paste counter:', pasteCounter);
        const response = await fetch('https://henosis.us/api/save_conversation', {
          method: 'POST',
          credentials: 'include', // Include cookies
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            thread_id: threads[currentThreadIndex]?.id || null,
            thread_name: threadName,
            messages: messagesToSave,
            paste_counter: pasteCounter,
          }),
        });
        if (!response.ok) {
          if (response.status === 401) {
            onLogout();
          }
          throw new Error('Failed to save conversation');
        }
        const data = await response.json();
        console.log('Saved conversation response:', data);
        if (data.thread_id) {
          setThreads((prevThreads) => {
            const updatedThreads = [...prevThreads];
            updatedThreads[currentThreadIndex] = {
              ...updatedThreads[currentThreadIndex],
              id: data.thread_id,
              pasteCounter: pasteCounter, // Update the paste counter in the thread state
            };
            return updatedThreads;
          });
        }
        return data.thread_id;
      } catch (error) {
        console.error('Error saving conversation:', error);
        if (error instanceof Error && error.message.includes('401')) {
          onLogout();
        }
        return null;
      }
    },
    [threads, currentThreadIndex, onLogout]
  );

  // Function to generate chat title
  const generateChatTitle = useCallback(
    async (initialQuery) => {
      const systemPrompt =
        'Your job is to create a short and kind chat title for the thread based on the initial query. The title should be no more than 5 words and should avoid any potentially offensive or inappropriate language. If the user\'s query contains any inappropriate words, phrases, or concepts, please replace them with something kind and positive. Focus on generating a title that is friendly and welcoming. The title should be concise and easy to understand, providing a general idea of the conversation\'s topic without revealing sensitive information.';
      const messagesForTitle = [
        { role: 'system', content: systemPrompt },
        { role: 'user', content: initialQuery },
      ];

      try {
        const response = await fetch('https://henosis.us/api/chat', {
          method: 'POST',
          credentials: 'include', // Include cookies
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            messages: messagesForTitle,
            model: 'gemini-1.5-flash-latest',
          }),
        });

        if (!response.ok) {
          if (response.status === 401) {
            onLogout();
          }
          throw new Error(`API call failed with status ${response.status}`);
        }

        const data = await response.json();
        const title = data.choices[0].message.content.trim();
        return title.split(' ').length > 6 ? 'Untitled Chat' : title;
      } catch (error) {
        console.error('Error generating chat title:', error);
        return 'Untitled Chat';
      }
    },
    [onLogout]
  );

  // Function to handle new chat
  const handleNewChat = useCallback(async () => {
    if (messages.length > 0) {
      await saveConversation(threads[currentThreadIndex].name, messages);
    }

    const newThread = {
      name: 'new chat',
      messages: [],
      selectedModel: 'gemini-1.5-flash-latest',
      createdAt: new Date().toISOString(), // Add creation time
    };

    setThreads((prevThreads) => [newThread, ...prevThreads]); // Add new thread at the beginning
    setCurrentThreadIndex(0);
    setMessages([]);
    setInputMessage('');
    setPastes([]);
    messageHeightsRef.current = {}; // Reset heights
    if (listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [messages, threads, currentThreadIndex, saveConversation]);

  // Function to adjust textarea height
  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputMessage, adjustTextareaHeight]);

  const debouncedAdjustTextareaHeight = useMemo(
    () => debounce(adjustTextareaHeight, 100),
    [adjustTextareaHeight]
  );

  // Function to handle input change
  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value.length > 2000) {
        const newPasteIndex = pastes.length + 1;
        const newPaste = `<paste-${newPasteIndex}>${value}</paste-${newPasteIndex}>`;
        console.log(`Adding new paste: Paste ${newPasteIndex}`);
        setPastes((prevPastes) => [...prevPastes, newPaste]);
        setUploadedFiles((prevFiles) => [...prevFiles, `Paste ${newPasteIndex}`]);
        setInputMessage('');
      } else {
        setInputMessage(value);
      }
      debouncedAdjustTextareaHeight();
    },
    [pastes.length, debouncedAdjustTextareaHeight]
  );

  // Function to handle sending message
  const handleSendMessage = useCallback(async () => {
    if (!inputMessage.trim() && pastes.length === 0) return;

    let newMessageContent = `${pastes.join('\n')}\n${inputMessage}`.trim();

    if (messagesRef.current.length === 0 && customInstructions.trim()) {
      newMessageContent = `${customInstructions.trim()}\n${newMessageContent}`;
    }

    const newMessage = {
      role: 'user',
      content: newMessageContent,
    };
    const updatedMessages = [...messagesRef.current, newMessage];
    setMessages(updatedMessages);
    setInputMessage('');
    const pasteCount = pastes.length; // Store the paste count before resetting
    setPastes([]);
    setIsLoading(true);

    const selectedModel = threads[currentThreadIndex].selectedModel || 'deepseek-chat';

    try {
      const response = await fetch('https://henosis.us/api/chat', {
        method: 'POST',
        credentials: 'include', // Include cookies
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: updatedMessages, // Updated messages should be sent
          model: selectedModel,
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          onLogout();
        }
        throw new Error(`API call failed with status ${response.status}`);
      }

      const data = await response.json();
      setIsLoading(false);
      const assistantMessage = {
        role: 'assistant',
        content: data.choices[0].message.content,
        model: selectedModel,
      };
      const finalMessages = [...updatedMessages, assistantMessage];
      setMessages(finalMessages);

      console.log('Current thread name:', threads[currentThreadIndex].name);
      console.log('Current messages length:', messagesRef.current.length);

      if (threads[currentThreadIndex].name === 'new chat' && messagesRef.current.length === 1) {
        console.log('Attempting to generate chat title for new chat');
        const chatTitle = await generateChatTitle(newMessage.content);
        console.log('Generated chat title:', chatTitle);
        setThreads((prevThreads) => {
          const updatedThreads = [...prevThreads];
          updatedThreads[currentThreadIndex] = {
            ...updatedThreads[currentThreadIndex],
            name: chatTitle,
          };
          return updatedThreads;
        });
      }

      if (currentThreadIndex === threads.length - 1) {
        const newThread = {
          ...threads[currentThreadIndex],
          messages: finalMessages,
          pasteCounter: pasteCount, // Add paste counter to the new thread
        };
        setThreads((prevThreads) => [...prevThreads, newThread]);
        console.log('Saving new thread with paste counter:', pasteCount);
        await saveConversation(newThread.name, finalMessages, pasteCount);
      } else {
        setThreads((prevThreads) => {
          const updatedThreads = [...prevThreads];
          updatedThreads[currentThreadIndex] = {
            ...updatedThreads[currentThreadIndex],
            messages: finalMessages,
            pasteCounter: (updatedThreads[currentThreadIndex].pasteCounter || 0) + pasteCount, // Increment paste counter
          };
          return updatedThreads;
        });
        console.log(
          'Saving existing thread with paste counter:',
          (threads[currentThreadIndex].pasteCounter || 0) + pasteCount
        );
        await saveConversation(
          threads[currentThreadIndex].name,
          finalMessages,
          (threads[currentThreadIndex].pasteCounter || 0) + pasteCount
        );
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error sending message:', error);

      // Enhanced error logging
      if (error instanceof Error) {
        console.error('Error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack,
        });
      }

      // If the error response contains more details, log them
      if (error.response) {
        console.error('Server response:', {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data,
        });
      }

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'system',
          content: `An error occurred while sending the message: ${
            error.message || 'Unknown error'
          }`,
        },
      ]);
    }
  },
  [
    inputMessage,
    pastes,
    customInstructions,
    currentThreadIndex,
    threads,
    generateChatTitle,
    onLogout,
    saveConversation,
  ]);

  // Function to handle deleting a paste
  const handleDeletePaste = useCallback((index) => {
    setPastes((prevPastes) => prevPastes.filter((_, i) => i !== index));
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }, []);

  // Function to handle file upload
  const handleFileUpload = useCallback((event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    files.forEach((file) => {
      const reader = new FileReader();

      const supportedTypes = [
        'text/plain',
        'application/json',
        'text/markdown',
        'text/xml',
        'application/xml',
        'text/csv',
        'application/javascript',
      ];

      if (supportedTypes.includes(file.type)) {
        reader.onload = (e) => {
          const textContent = e.target.result;
          const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9-_\.]/g, '');
          const fileTag = `<${sanitizedFileName}>${textContent}</${sanitizedFileName}>`;
          setPastes((prevPastes) => [...prevPastes, fileTag]);
          setUploadedFiles((prevFiles) => [...prevFiles, file.name]);
        };

        reader.onerror = () => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { role: 'system', content: `Failed to read the file "${file.name}".` },
          ]);
        };

        reader.readAsText(file);
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: 'system',
            content: `Unsupported file type for "${file.name}". Only text-based files are supported.`,
          },
        ]);
      }
    });

    event.target.value = null;
  }, []);

  // Function to toggle settings
  const handleSettingsToggle = useCallback(() => {
    setIsSettingsOpen((prev) => !prev);
  }, []);

  // Function to get conversations
  const getConversations = useCallback(async () => {
    try {
      const response = await fetch('https://henosis.us/api/get_conversations', {
        method: 'GET',
        credentials: 'include', // Include cookies
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          onLogout();
        }
        throw new Error('Failed to get conversations');
      }
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        const sortedThreads = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setThreads(
          sortedThreads.map((thread) => ({
            ...thread,
            pasteCounter: thread.pasteCounter || 0,
          }))
        );
        setCurrentThreadIndex(0);
        setMessages(sortedThreads[0].messages || []);
      } else {
        console.log('No threads received from server');
        setThreads([]);
      }
      setThreadsLoaded(true);
    } catch (error) {
      console.error('Error getting conversations:', error);
      if (error instanceof Error && error.message.includes('401')) {
        onLogout();
      }
      setThreads([]);
      setThreadsLoaded(true);
    }
  }, [onLogout]);

  // Function to check authentication
  const checkAuth = useCallback(async () => {
    try {
      const response = await fetch('https://henosis.us/api/check-auth', {
        method: 'GET',
        credentials: 'include', // Include cookies
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          onLogout();
        }
        throw new Error(`Authentication check failed with status ${response.status}`);
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      onLogout();
    }
  }, [onLogout]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    if (!threadsLoaded) {
      getConversations();
    }
  }, [threadsLoaded, getConversations]);

  // Function to handle saving settings
  const handleSaveSettings = useCallback(async () => {
    try {
      const response = await fetch('https://henosis.us/api/save_custom_instructions', {
        method: 'POST',
        credentials: 'include', // Include cookies
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          instructions: customInstructions.trim(),
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          onLogout();
        }
        const errorData = await response.json();
        throw new Error(
          errorData.error || `Failed to save custom instructions: ${response.status}`
        );
      }

      const data = await response.json();
      console.log('Custom instructions saved:', data.message);

      // Optionally, you can display a success message to the user here
    } catch (error) {
      console.error('Error saving custom instructions:', error);
      if (error instanceof Error && error.message.includes('401')) {
        onLogout();
      }
      // Optionally, you can display an error message to the user here
    }

    setIsSettingsOpen(false);
  }, [customInstructions, onLogout]);

  // Fetch custom instructions on mount
  useEffect(() => {
    const fetchCustomInstructions = async () => {
      try {
        const response = await fetch('https://henosis.us/api/get_custom_instructions', {
          method: 'GET',
          credentials: 'include', // Include cookies
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCustomInstructions(data.instructions);
        } else {
          if (response.status === 401) {
            onLogout();
          }
          const errorData = await response.json();
          console.error(
            `Failed to fetch custom instructions: ${errorData.error || response.status}`
          );
        }
      } catch (error) {
        console.error('Error fetching custom instructions:', error);
        if (error instanceof Error && error.message.includes('401')) {
          onLogout();
        }
      }
    };

    fetchCustomInstructions();
  }, [onLogout]);

  // Function to handle thread change
  const handleThreadChange = useCallback(
    async (newIndex) => {
      if (messagesRef.current.length > 0) {
        await saveConversation(threads[currentThreadIndex].name, messagesRef.current);
      }

      setCurrentThreadIndex(newIndex);
      setMessages(threads[newIndex].messages);
      setCustomInstructions(threads[newIndex].customInstructions || '');
      messageHeightsRef.current = {}; // Reset heights
      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
      }
    },
    [saveConversation, threads, currentThreadIndex]
  );

  // Function to delete a thread
  const deleteThread = useCallback(async (threadId) => {
    try {
      const response = await fetch(`https://henosis.us/api/delete_conversation/${threadId}`, {
        method: 'DELETE',
        credentials: 'include', // Include cookies
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          onLogout();
        }
        throw new Error('Failed to delete conversation');
      }
      return true;
    } catch (error) {
      console.error('Error deleting conversation:', error);
      if (error instanceof Error && error.message.includes('401')) {
        onLogout();
      }
      return false;
    }
  }, [onLogout]);

  // Function to handle deleting current thread
  const handleDeleteThread = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this thread?')) {
      const currentThread = threads[currentThreadIndex];
      if (currentThread && currentThread.id) {
        const deleted = await deleteThread(currentThread.id);
        if (deleted) {
          const newThreads = threads.filter((_, index) => index !== currentThreadIndex);
          setThreads(newThreads);
          setCurrentThreadIndex(0);
          setMessages(newThreads[0]?.messages || []);
          setIsSettingsOpen(false);
          messageHeightsRef.current = {}; // Reset heights
          if (listRef.current) {
            listRef.current.resetAfterIndex(0, true);
          }
        } else {
          alert('Failed to delete the thread. Please try again.');
        }
      } else {
        const newThreads = threads.filter((_, index) => index !== currentThreadIndex);
        setThreads(newThreads);
        setCurrentThreadIndex(0);
        setMessages(newThreads[0]?.messages || []);
        setIsSettingsOpen(false);
        messageHeightsRef.current = {}; // Reset heights
        if (listRef.current) {
          listRef.current.resetAfterIndex(0, true);
        }
      }
    }
  }, [threads, currentThreadIndex, deleteThread]);

  // Function to render each row in the virtualized list
  const renderRow = useCallback(
    ({ index, style }) => (
      <Message
        index={index}
        style={style}
        data={{
          messages,
          setMessageHeight: (i, height) => {
            if (messageHeightsRef.current[i] !== height) {
              messageHeightsRef.current[i] = height;
              if (listRef.current) {
                listRef.current.resetAfterIndex(i, true);
              }
            }
          },
        }}
      />
    ),
    [messages]
  );

  // Loading indicator component
  const LoadingBubble = React.memo(() => (
    <div className="flex items-center space-x-2 bg-gray-700 p-3 rounded-lg z-50 relative">
      <div
        className="w-2 h-2 bg-white rounded-full animate-bounce"
        style={{ animationDelay: '0s' }}
      ></div>
      <div
        className="w-2 h-2 bg-white rounded-full animate-bounce"
        style={{ animationDelay: '0.2s' }}
      ></div>
      <div
        className="w-2 h-2 bg-white rounded-full animate-bounce"
        style={{ animationDelay: '0.4s' }}
      ></div>
    </div>
  ));

  // Sidebar mouse event handlers
  const handleSidebarMouseEnter = useCallback(() => {
    setIsMouseOverSidebar(true);
    if (sidebarCloseTimeoutRef.current) {
      clearTimeout(sidebarCloseTimeoutRef.current);
      sidebarCloseTimeoutRef.current = null;
    }
  }, []);

  const handleSidebarMouseLeave = useCallback(() => {
    setIsMouseOverSidebar(false);
    sidebarCloseTimeoutRef.current = setTimeout(() => {
      setIsSidebarOpen(false);
    }, 200);
  }, []);

  const handleTriggerZoneMouseEnter = useCallback(() => {
    setIsSidebarOpen(true);
    if (sidebarCloseTimeoutRef.current) {
      clearTimeout(sidebarCloseTimeoutRef.current);
      sidebarCloseTimeoutRef.current = null;
    }
  }, []);

  // Function to get item size for virtualization
  const getItemSize = useCallback((index) => {
    return messageHeightsRef.current[index] ? messageHeightsRef.current[index] + 16 : 100; // Add some padding
  }, []);

  return (
    <div className="flex flex-col h-screen bg-[#2c2c2c] text-white relative">
      {/* Header */}
      <header className="flex justify-between items-center p-3 bg-[#2c2c2c] w-full z-30">
        {/* Henosis Logo and Text */}
        <div className="flex items-center">
          <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
          <div className="text-lg font-bold">henosis</div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleSettingsToggle}
            className="p-1.5 bg-orange-500 rounded hover:bg-orange-600 transition-colors"
          >
            <Settings size={15} />
          </button>
          <button
            onClick={onLogout}
            className="p-1.5 bg-red-500 rounded hover:bg-red-600 transition-colors"
          >
            Logout
          </button>
        </div>
      </header>

      <div className="flex flex-grow overflow-hidden">
        {/* Trigger Zone */}
        <div
          className="fixed left-0 top-16 bottom-0 z-10"
          style={{ width: '50px' }}
          onMouseEnter={handleTriggerZoneMouseEnter}
        ></div>

        {/* Sidebar */}
        <div
          className={`bg-[#1a1a1a] ${isSidebarOpen ? 'w-64' : 'w-0'} rounded-r-lg transition-all duration-300 flex flex-col overflow-hidden z-20 fixed top-16 left-0 bottom-0`}
          onMouseEnter={handleSidebarMouseEnter}
          onMouseLeave={handleSidebarMouseLeave}
        >
          <div className="flex flex-col h-full">
            {isSidebarOpen && (
              <>
                {/* Sidebar Indicator */}
                <div className="absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-full bg-orange-500 h-8 w-2 rounded-full"></div>

                <button
                  onClick={handleNewChat}
                  className="flex items-center p-4 hover:bg-gray-800 w-full text-left transition-colors"
                >
                  <Plus size={20} className="mr-2" />
                  New Chat
                </button>
                <div className="flex-grow overflow-y-auto">
                  {threads.map((thread, index) => (
                    <button
                      key={thread.id || index}
                      onClick={() => handleThreadChange(index)}
                      className={`w-full text-left p-4 cursor-pointer hover:bg-gray-800 transition-colors ${
                        currentThreadIndex === index ? 'bg-gray-700' : ''
                      }`}
                    >
                      {thread.name}
                    </button>
                  ))}
                </div>
                <div className="mt-auto p-4 bg-[#1a1a1a]">
                  <button className="w-full p-2 bg-gray-800 rounded mb-2 hover:bg-gray-700 transition-colors">
                    Profile
                  </button>
                  <button className="w-full p-2 bg-gray-800 rounded hover:bg-gray-700 transition-colors">
                    Paid Plans
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Main chat area */}
        <div className="flex-grow flex flex-col ml-0 overflow-hidden">
          <div className="mx-auto w-full sm:w-[70%] flex flex-col flex-grow overflow-hidden">
            {/* Chat messages area with virtualization */}
            <div className="flex-grow flex flex-col overflow-hidden" ref={containerRef}>
              {listHeight > 0 && (
                <List
                  height={listHeight - (isLoading ? 50 : 0)} // Adjust height if LoadingBubble is visible
                  itemCount={messages.length}
                  itemSize={getItemSize}
                  width="100%"
                  ref={listRef}
                  itemData={{
                    messages,
                    setMessageHeight: (i, h) => {
                      if (messageHeightsRef.current[i] !== h) {
                        messageHeightsRef.current[i] = h;
                        if (listRef.current) {
                          listRef.current.resetAfterIndex(i, true);
                        }
                      }
                    },
                  }}
                >
                  {renderRow}
                </List>
              )}
              {isLoading && (
                <div className="text-left mb-4 mt-2">
                  <LoadingBubble />
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            {/* Input area */}
            <div className="p-4 bg-[#3a3a3a] rounded-lg">
              <div className="flex flex-col bg-gray-700 rounded-lg">
                {pastes.length > 0 && (
                  <div className="flex flex-wrap p-2 border-b border-gray-600">
                    {pastes.map((paste, index) => (
                      <button
                        key={index}
                        className="bg-gray-600 text-xs px-2 py-1 rounded mr-1 mb-1 hover:bg-gray-500 flex items-center transition-colors"
                        title={`Paste ${index + 1}`}
                        onClick={() => handleDeletePaste(index)}
                      >
                        {uploadedFiles[index] || `Paste ${index + 1}`} <X size={12} className="ml-1" />
                      </button>
                    ))}
                  </div>
                )}
                <div className="flex items-center">
                  <textarea
                    ref={textareaRef}
                    value={inputMessage}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                      }
                    }}
                    placeholder="Type a message..."
                    className="flex-grow bg-transparent p-2 outline-none resize-none"
                    rows="1"
                  />
                  <button
                    onClick={() => fileInputRef.current.click()}
                    className="p-2 text-gray-400 hover:text-gray-300 transition-colors"
                  >
                    <Paperclip size={20} />
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                    multiple
                    style={{ display: 'none' }}
                  />
                  <button
                    onClick={handleSendMessage}
                    className="bg-orange-500 text-white p-2 rounded-r-lg hover:bg-orange-600 transition-colors"
                  >
                    <Send size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Settings popup */}
      {isSettingsOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
          <div className="bg-[#3a3a3a] p-6 rounded-lg w-96 relative">
            <h2 className="text-xl mb-4">Settings</h2>
            <div className="relative mb-4">
              <label className="block text-sm font-medium mb-2">Select Model</label>
              <button
                onClick={() => setIsModelSelectorOpen((prev) => !prev)}
                className="flex items-center bg-gray-700 rounded px-2 py-1 text-sm w-full hover:bg-gray-600 transition-colors"
              >
                {models.find(
                  (m) => m.value === (threads[currentThreadIndex].selectedModel || 'deepseek-chat')
                )?.label || 'Select Model'}{' '}
                <ChevronDown className="ml-1" />
              </button>
              {isModelSelectorOpen && (
                <div className="absolute top-full left-0 bg-gray-700 rounded shadow-lg max-h-60 overflow-y-auto z-10 w-full">
                  {models.map((model) => (
                    <div
                      key={model.value}
                      className="px-4 py-2 hover:bg-gray-600 cursor-pointer text-sm transition-colors"
                      onClick={() => {
                        setThreads((prevThreads) => {
                          const updatedThreads = [...prevThreads];
                          updatedThreads[currentThreadIndex] = {
                            ...updatedThreads[currentThreadIndex],
                            selectedModel: model.value,
                          };
                          return updatedThreads;
                        });
                        setIsModelSelectorOpen(false);
                      }}
                    >
                      {model.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <textarea
              value={customInstructions}
              onChange={(e) => setCustomInstructions(e.target.value)}
              placeholder="Enter custom instructions..."
              className="w-full p-2 mb-4 bg-gray-700 rounded hover:bg-gray-600 transition-colors"
              rows="4"
            />
            <button
              onClick={handleDeleteThread}
              className="w-full bg-red-500 text-white p-2 rounded mb-4 hover:bg-red-600 transition-colors"
            >
              Delete Current Thread
            </button>
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleSaveSettings}
                className="bg-orange-500 text-white p-2 rounded hover:bg-orange-600 transition-colors"
              >
                Save
              </button>
              <button
                onClick={() => setIsSettingsOpen(false)}
                className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 transition-colors"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
