import React, { useState } from 'react';

const LoginForm = ({ onLogin }) => {
  const [loginData, setLoginData] = useState({ username: '', password: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(loginData);
  };

  return (
    <div className="flex items-center justify-center h-screen bg-[#2c2c2c]">
      <form onSubmit={handleSubmit} className="bg-[#3a3a3a] p-8 rounded-lg shadow-md">
        <h2 className="text-2xl mb-4 text-white">Login</h2>
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={loginData.username}
          onChange={handleInputChange}
          className="w-full p-2 mb-4 bg-gray-700 rounded text-white"
          autoComplete="username"
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={loginData.password}
          onChange={handleInputChange}
          className="w-full p-2 mb-4 bg-gray-700 rounded text-white"
          autoComplete="current-password"
        />
        <button type="submit" className="w-full bg-orange-500 text-white p-2 rounded">
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;